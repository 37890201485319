import Cookies from 'js-cookie'
import { COMFINO_SID_KEY } from '~/utils/consts'

function removeSession (token) {
    const URLObject = new URL(window.location)
    const cookieDomain = URLObject.host.replace('wniosek', '')

    Cookies.remove(COMFINO_SID_KEY, { path: '/', domain: cookieDomain })
}

function getVerifyPath (token) {
    return `/ecommerce/verify?token=${token}`
}

export default function processUnauthorized (redirect, token) {
    if (!token) {
        return
    }
    removeSession(token)
    redirect(getVerifyPath(token))
}
